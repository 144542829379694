import axios, {
  type AxiosRequestConfig,
  type Method,
  type AxiosRequestHeaders,
  type ResponseType,
  type AxiosPromise,
} from "axios";
import _ from "lodash";

type FetchParams = {
  data?: object;
  url: string;
  headers?: any;
  params?: any;
  responseType?:
    | "arraybuffer"
    | "blob"
    | "document"
    | "json"
    | "text"
    | "stream";
  decompress?: boolean;
};

type PostParams = {
  data: object;
  url: string;
  headers?: AxiosRequestHeaders;
  responseType?:
    | "arraybuffer"
    | "blob"
    | "document"
    | "json"
    | "text"
    | "stream";
  decompress?: boolean;
};

export function axiosFetch<T>(params: FetchParams): AxiosPromise<T> {
  const options: AxiosRequestConfig<any> = {
    url: params.url,
    headers: _.get(params, "headers", {}) as AxiosRequestHeaders,
    data: _.get(params, "data", {}), // Body
    params: _.get(params, "params", {}) as string, // Querystring
    responseType: _.get(params, "responseType", "json") as ResponseType,
    decompress: _.get(params, "decompress", false),
  };

  return axios.get<T>(params.url, options);
}

export function axiosPost<T>(params: PostParams): AxiosPromise<T> {
  const { url, data, headers } = params;

  return axios.post<T>(url, data, { headers });
}
export default class ApiClient {
  async fetch(params: FetchParams) {
    const options: AxiosRequestConfig<any> = {
      method: _.get(params, "method", "GET") as Method,
      url: params.url,
      headers: _.get(params, "headers", {}) as AxiosRequestHeaders,
      data: _.get(params, "data", {}), // Body
      params: _.get(params, "params", {}) as string, // Querystring
      responseType: _.get(params, "responseType", "json") as ResponseType,
      decompress: _.get(params, "decompress", false),
    };

    const response = await axios(options);

    return response;
  }
}
