/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @next/next/no-img-element */
import React, { useContext, useRef, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useOnClickOutside } from "usehooks-ts";
import { useRouter } from "next/router";
import { AuthContext } from "@/context/AuthContext";
import { useMsal } from "@azure/msal-react";
import { USER_ROLE } from "@/types/user";
import {
  HomeIcon,
  UserMinusIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";

// define a NavItem prop
export type NavItem = {
  label: string;
  href: string;
  icon: React.ReactNode;
};
// add NavItem prop to component prop
type Props = {
  open: boolean;
  setOpen(open: boolean): void;
};
const Sidebar = ({ open, setOpen }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { user } = useContext(AuthContext);
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const { pathname } = useRouter();
  const { instance } = useMsal();

  React.useEffect(() => {
    if (user?.role === USER_ROLE.invoice_manager) {
      const items = [
        {
          label: "Dashboard",
          href: "/",
          icon: <HomeIcon className="text-brand-blue-600 h-6" />,
        },
        {
          label: "Unassigned",
          href: "/unassigned",
          icon: <UserMinusIcon className="text-brand-blue-600 h-6" />,
        },
        {
          label: "Assigned",
          href: "/assigned",
          icon: <UserPlusIcon className="text-brand-blue-600 h-6" />,
        },
      ];
      setNavItems(items);
    }

    if (user?.role === USER_ROLE.invoice_triage) {
      const items = [
        {
          label: "Unassigned",
          href: "/unassigned",
          icon: null,
        },
      ];
      setNavItems(items);
    }

    if (user?.role === USER_ROLE.invoice_specialist) {
      const items = [
        {
          label: "Invoicing Opportunities",
          href: "/my-tasks",
          icon: <UserPlusIcon className="text-brand-blue-600 h-6" />,
        },
      ];
      setNavItems(items);
    }
  }, [user?.role]);

  const handleLogout = async () => {
    await instance.logoutPopup({
      postLogoutRedirectUri: "/",
      mainWindowRedirectUri: "/",
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  useOnClickOutside(ref, (e) => {
    setOpen(false);
  });
  return (
    <div
      className={classNames({
        "flex flex-col justify-between": true, // layout
        "bg-white text-black": true, // colors
        "fixed top-0 z-20 md:sticky md:top-16 md:z-0 md:w-full": true, // positioning
        "w-[300px] md:h-[calc(100vh_-_64px)]": true, // for height and width
        ".3s transition-transform ease-in-out md:-translate-x-0": true, //animations
        "-translate-x-full ": !open, //hide sidebar to the left when closed
        "shadow shadow-zinc-500/40": true,
      })}
      ref={ref}
    >
      <nav className="top-0 mt-4 md:sticky md:top-16">
        {/* nav items */}
        <ul className="flex flex-col gap-2 py-2">
          {navItems.map((item, index) => {
            return (
              <Link key={index} href={item.href}>
                <li
                  className={classNames({
                    "text-gray-600 hover:bg-gray-100 hover:text-gray-900":
                      pathname !== item.href, //colors
                    "is-selected bg-gray-100 text-gray-900":
                      pathname === item.href,
                    "group flex items-center gap-4 ": true, //layout
                    "transition-colors duration-300": true, //animation
                    "mx-2 rounded-md p-2 pl-4": true, //self style
                  })}
                  data-testid={`menu-${item.label.toLowerCase()}`}
                >
                  {item.icon} {item.label}
                </li>
              </Link>
            );
          })}
        </ul>
      </nav>
      {/* account  */}
      <div className="border-t border-stone-300 p-4">
        <div className="flex flex-row items-center">
          <img
            className="mr-2 inline-block h-14 w-14 rounded-full ring-2 ring-white"
            src={"/avatar.png"}
            alt="User avatar"
          />

          <div className="flex flex-col justify-items-start">
            <p className="font-medium text-black">{user && user.fullName}</p>
            <button
              className="text-left text-sm text-black hover:font-bold"
              onClick={() => void handleLogout()}
              data-testid="sign-out-button"
            >
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
