import * as msal from "@azure/msal-browser";
import { env } from "@/env.mjs";

const msalConfig = {
  auth: {
    clientId: env.NEXT_PUBLIC_AZURE_AD_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
    authority:
      "https://login.microsoftonline.com/" + env.NEXT_PUBLIC_AZURE_AD_TENANT_ID,
    redirectUri: env.NEXT_PUBLIC_AUTH_REDIRECT_URL,
    // postLogoutRedirectUri: "/login", // Indicates the page to navigate after logout.
    // navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
export const loginRequest = {
  scopes: ["User.Read"],
};

export const apiRequest = {
  scopes: ["https://api-dev.roshalimaging.org/api/user_impersonation"],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance };
