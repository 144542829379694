import React, { useEffect, useState } from "react";
import { notificationAction } from "@/reducer";
import useNotification from "@/hook/useNotification";
import { XMarkIcon } from "@heroicons/react/20/solid";

function NotificationCard({
  type,
  message,
}: {
  type: string;
  message: string;
}) {
  const { closeNotification } = useNotification();
  const [bgColor, setBgColor] = useState("bg-white");
  const [textColor, setTextColor] = useState("bg-white");
  useEffect(() => {
    switch (type) {
      case notificationAction.ALERT:
        setBgColor("bg-brand-blue-50");
        setTextColor("text-brand-blue-600");
        break;
      case notificationAction.ERROR:
        setBgColor("bg-brand-red-50");
        setTextColor("text-brand-red-800");
        break;
      case notificationAction.SUCCESS:
        setBgColor("bg-brand-green-50");
        setTextColor("text-brand-green-800");
        break;
      case notificationAction.WARNING:
        setBgColor("bg-brand-yellow-50");
        setTextColor("text-brand-yellow-800");
        break;
      default:
        setBgColor("bg-gray-300");
        break;
    }
  }, [type, message]);

  const onCloseNotification = () => {
    if (closeNotification) {
      closeNotification(0);
    }
  };

  return (
    <div
      className={`card absolute right-8 top-2 rounded-lg p-3 ${bgColor} ${textColor} text-sm shadow-md`}
    >
      <div className="card-body flex min-w-[300px] justify-between">
        <span>{message}</span>
        <button
          onClick={onCloseNotification}
          className="ml-4 h-5 w-5 text-gray-400"
        >
          <XMarkIcon />
        </button>
      </div>
    </div>
  );
}

export default NotificationCard;
