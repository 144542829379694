import { USER_ROLE } from "@/types/user";

export const ROUTES = {
  MY_TASK: "/my-tasks",
  DASHBOARD: "/",
  ASSIGNED: "/assigned",
  UNASSIGNED: "/unassigned",
  INVOICING_OPPORTUNITY: "/invoicing-opportunity",
};

export const USER_ROUTES = {
  [USER_ROLE.invoice_specialist]: {
    defaultRoute: "/my-tasks",
    enableRoutes: [ROUTES.MY_TASK, ROUTES.INVOICING_OPPORTUNITY],
  },
  [USER_ROLE.invoice_manager]: {
    defaultRoute: "/",
    enableRoutes: [
      ROUTES.DASHBOARD,
      ROUTES.ASSIGNED,
      ROUTES.UNASSIGNED,
      ROUTES.INVOICING_OPPORTUNITY,
    ],
  },
};
