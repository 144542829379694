import Layout from "../components/layout";
import "@/styles/globals.css";
import { MsalProvider } from "@azure/msal-react";
import "@/styles/globals.css";
import { msalInstance } from "@/services/msal";
import { AuthProvider } from "@/context/AuthContext";
import React, { useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-tailwindcss-select/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Notification from "@/context/Notification";

const MyApp: ({
  Component,
  pageProps: { ...pageProps },
}: {
  Component: any;
  pageProps: { [p: string]: any };
}) => React.JSX.Element = ({ Component, pageProps: { ...pageProps } }) => {
  const [queryClient] = useState(() => new QueryClient());
  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider msalInstance={msalInstance}>
        <QueryClientProvider client={queryClient}>
          <Layout>
            <Notification>
              <Component {...pageProps} />
            </Notification>
          </Layout>
        </QueryClientProvider>
      </AuthProvider>
    </MsalProvider>
  );
};

export default MyApp;
