import { type UserExecutions } from "@/types/callbotRecord";

export type UserExecutionByUserStatus = {
  code: string;
  created_at: string;
  id: number;
  label_group_id: number;
  name: string;
  updated_at: string;
};

export type UserExecutionByUser = {
  id: number;
  zoho_id: string;
  type_id: number;
  status_id: number;
  status: UserExecutionByUserStatus;
  first_name: string;
  last_name: string;
  email: string | null;
  created_at: string;
  date_of_birth: string;
  executions: UserExecutions[];
  updated_at: string;
  phone: string;
};

export enum USER_ROLE {
  invoice_triage = "invoice_triage",
  invoice_specialist = "invoice_team",
  invoice_manager = "invoice_manager",
}

type UseDistributeInvoicesRequestUser = {
  full_data: {
    id: string;
  };
};

type UseDistributeInvoicesRequestExecutions = {
  id: string;
};

export interface UseDistributeInvoicesRequest {
  users: UseDistributeInvoicesRequestUser[];
  executions: UseDistributeInvoicesRequestExecutions[];
  distribution:
    | "all-even"
    | "all-tasks-select-users"
    | "selected-tasks-even"
    | "selected-tasks-selected-users";
}

export interface UseUnassignedInvoicesRequest {
  ids: string[];
}
