import {
  type NotificationState,
  type NotificationAction,
} from "@/types/notification";
import { notificationAction } from "@/reducer/notificationAction";

export const notificationInitialState: NotificationState = {
  notifications: [],
};

export default (
  state: NotificationState = notificationInitialState,
  { type, payload }: NotificationAction
): NotificationState => {
  switch (type) {
    case notificationAction.ADD:
      // Add notification to the list (state..notifications)
      if (payload.notification) {
        return {
          notifications: [...state.notifications, payload.notification],
        };
      }
      return state;
    case notificationAction.DELETE:
      // Remove/Delete notification
      const deleteNotification = state.notifications?.filter(
        (notification) => notification.id !== payload.id
      );
      return { notifications: [...deleteNotification] };
    case notificationAction.INACTIVE:
      // Make notification inactive
      const notifications = state.notifications?.map((notification) => {
        if (notification.id === payload.id) {
          return {
            ...notification,
            active: false,
          };
        }
        return notification;
      });
      return { notifications: [...notifications] };
    default:
      return state;
  }
};
