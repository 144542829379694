import React, {type PropsWithChildren, useContext, useState} from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Login from "../login";
import {AuthContext} from "@/context/AuthContext";
import ErrorBoundary from "../error-boundary";
import {useRouter} from "next/router";
import Loading from "../shared/loading";

const Layout = (props: PropsWithChildren) => {
    const [showSidebar, setShowSidebar] = useState(false);
    const {pathname} = useRouter();
    const {user, hasUserAccess, isFetchingUser} = useContext(AuthContext);

    if (isFetchingUser && !user) {
        return <div className="flex h-screen w-full justify-center items-center"><Loading/></div>
    }

    if (!user || !hasUserAccess(user, pathname)) {
        return <Login/>
    }

    return (
        <div className="grid min-h-screen grid-rows-header bg-gray-100">
            <div className="z-10 bg-white shadow-sm">
                <Navbar onMenuButtonClick={() => setShowSidebar((prev) => !prev)}/>
            </div>
            <div className="grid md:grid-cols-sidebar ">
                <Sidebar open={showSidebar} setOpen={setShowSidebar}/>
                <div className="mx-8 my-6 rounded-lg bg-white">
                    <ErrorBoundary>{props.children}</ErrorBoundary>
                </div>
            </div>
        </div>
    );
};

export default Layout;
